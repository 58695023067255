import { useEffect, useMemo, useState } from 'react';

import { FilterExpression, FilterExpressionHelpers, TimeFilter, TimePrecision } from '@playq/octopus-common';

import { DateTimePicker } from '/shared/DateTimePicker';
import { DateRange, DateRangePicker } from '/shared/DateRangePicker';
import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';
import { FilterExpressionsSelect } from '/shared/Table/components/TableHeadCell/FilterExpressionsSelect';
import { useTimeFilterClasses } from '/shared/Table/components/TableHeadCell';

export function TimeFilterCell({ filter, onSubmit, withTimePresets = true }: IFilterCellProps) {
  const timeFilterCurrent = filter as TimeFilter;

  const [dateRange, setDateRange] = useState<DateRange>(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!timeFilterCurrent) {
      return [null, null];
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return [timeFilterCurrent.left || null, timeFilterCurrent.right || null];
  });

  const [expression, setExpression] = useState(() => {
    if (!filter) {
      return FilterExpressionHelpers.all[0];
    }

    return timeFilterCurrent.expression;
  });

  const classes = useTimeFilterClasses();
  const isRight = useMemo(
    () => expression === FilterExpression.OutOfRange || expression === FilterExpression.InRange,
    [expression]
  );

  const handleSingleChange = (date: Date) => {
    setDateRange([date, null]);
  };

  const handleDateRangeChange = (nextDateRange: DateRange) => {
    setDateRange(nextDateRange);
  };

  useEffect(() => {
    if (!dateRange[0] || (isRight && !dateRange[1])) {
      return;
    }

    const timeFilter = new TimeFilter();

    timeFilter.left = dateRange[0];

    if (isRight) {
      timeFilter.right = dateRange[1] || undefined;
    }

    timeFilter.expression = expression;
    timeFilter.precision = TimePrecision.Day;

    setTimeout(() => {
      onSubmit(timeFilter, false);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, isRight, expression]);

  return (
    <div>
      <FilterExpressionsSelect className={classes.expression} initialExp={expression} onChange={setExpression} />

      {!isRight ? (
        <DateTimePicker
          date={dateRange[0] ?? new Date()}
          className={classes.dateTimePicker}
          onChange={handleSingleChange}
        />
      ) : (
        <DateRangePicker
          dateRange={dateRange}
          onDateRangeChange={handleDateRangeChange}
          withTimePresets={withTimePresets}
        />
      )}
    </div>
  );
}

TimeFilterCell.type = TimeFilter.ClassName;
