import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import urltron from 'urltron';
import { Mutation, MutationKey, QueryKey, useIsFetching, useIsMutating, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useEventCallback } from '@mui/material';

import { Filter, PromotionFilter, SortDirection } from '@playq/octopus-common';

import { currentModuleToolkit } from '/store';
import { entitiesLimitOptions } from '/constants';
import { QueryHelpers } from '/helpers/query';
import { clipboardWrite } from '/helpers/clipboard';
import { createStorage } from '/storage';
import { useDebounce } from '/hooks/useDebounce';
import { snackbarService } from '/common/snackbarService';
import {
  getPredefinedComponents,
  getPredefinedOptions,
  getPredefinedQuery,
  getQueryFromURLSearchParams,
  getRenderColumnsCount,
  getRowData,
  isPageResetNeeded,
  useGetPredefinedClasses,
} from '/shared/Table/helpers';
import { ITableColumnSort, ITableProps, ITableQuery, ITableState, QueryChangeType } from '/shared/Table/interfaces';
import { IRowData } from '/shared/Table/interfaces/IRowData';
import { activeSpaceToolkit } from '/store/toolkits/space';
import { updateQueryKeysIterator } from '/helpers';

const storage = createStorage();

const columnsCachePrefix = '#columns_';

export function useTableState<D, S extends string, F extends string>(props: ITableProps<D, S, F>): ITableState<D> {
  const {
    onQueryChange,
    onSelectionChange,
    refetch,
    onRowClick,
    getEntityURL,

    initialQuery: propsInitialQuery,
    options: propsOptions,
    components: propsComponents,

    getRowKey,
    placeholder,
    queryTags,
    cacheKey,
    data,
    columns,
    error,
    details,
    size = 'small',
    rowsPerPageOptions = entitiesLimitOptions.slice(),
    total = props.total ?? props.data.length,
    toolbarActions = [],
    toolbarActionsLength = 2,
    actions = [],
    actionsLength = 3,
    disableQueryShare = false,
    isMasterSelectionDisabled,
    disableSelection,
    hiddenColumns: hiddenFilterColumns,
    queryKeys,
    mutationsKeys,
    setEnableQueryEntities,

    customQueryRowComponent = null,
  } = props;

  const isMutatingPredicate = useEventCallback(
    (keys?: MutationKey[]) =>
      ({ options: mutationOptions }: Mutation) =>
        keys !== undefined &&
        mutationOptions?.mutationKey !== undefined &&
        keys.some((mKey) => isEqual(mKey, mutationOptions.mutationKey))
  );

  const queryClient = useQueryClient();
  const isFetching = useIsFetching(queryKeys, { predicate: () => queryKeys !== undefined });
  const isCurrentPageMutating = useIsMutating({
    predicate: isMutatingPredicate(mutationsKeys?.currentPage),
  });
  const areRestPagesMutating = useIsMutating({
    predicate: isMutatingPredicate(mutationsKeys?.restPages),
  });
  const shouldUpdateAfterMutation = useRef<boolean>(false);
  const shouldRefetchAfterProcessing = useRef<boolean>(false);

  const [selectedRowsCount, setSelectedRowsCount] = useState(() => 0);
  const [masterSelected, setMasterSelected] = useState(() => false);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(() => {
    if (hiddenFilterColumns !== undefined) {
      return hiddenFilterColumns;
    }
    if (cacheKey) {
      const item = storage.get(columnsCachePrefix + cacheKey);
      if (item) {
        return JSON.parse(item) as string[];
      }
    }
    return [];
  });
  const debounceProcessing = useDebounce<boolean>(!!props.processing, 300);
  const classes = useGetPredefinedClasses(props.classes);

  const activeSpaceData = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const isSpaceLessModule = useSelector(currentModuleToolkit.selectors.isSpaceLessModule);

  const activeSpace = isSpaceLessModule ? undefined : activeSpaceData;

  const options = useMemo(() => getPredefinedOptions(propsOptions), [propsOptions]);
  const query = useMemo(() => getPredefinedQuery<string>(propsInitialQuery), [propsInitialQuery]);
  const renderColumnsCount = useMemo(
    () => getRenderColumnsCount(columns, hiddenColumns, options),
    [options, hiddenColumns, columns]
  );
  const [rows, setRows] = useState(() => getRowData(data));
  const components = useMemo(() => getPredefinedComponents(propsComponents), [propsComponents]);
  const promotionsFilter: { fieldName: string; filter: PromotionFilter } | undefined = useMemo(() => {
    const columnWithPromotionFilter = columns.find(({ filterType }) => filterType === PromotionFilter.ClassName);
    const fieldName = columnWithPromotionFilter?.filterField;
    if (!fieldName) {
      return;
    }
    const filter = query.filterBy[fieldName] as PromotionFilter | undefined;
    if (!filter) {
      return;
    }
    return { fieldName, filter };
  }, [columns, query.filterBy]);

  const updateEnableQueryEntitiesWithKeysChange = useEventCallback((keys?: QueryKey) => {
    if (!keys || !setEnableQueryEntities) {
      return;
    }
    const cachedData = queryClient.getQueryData(keys);
    setEnableQueryEntities(cachedData === undefined);
  });

  const updateEnableQueryEntitiesWithQueryChange = useEventCallback((change: QueryChangeType) => {
    if (!setEnableQueryEntities || !queryKeys) {
      return;
    }
    const wasPaginationClicked =
      change === QueryChangeType.FirstPage ||
      change === QueryChangeType.PrevPage ||
      change === QueryChangeType.NextPage;
    if (!wasPaginationClicked) {
      setEnableQueryEntities(true);
      return;
    }
    if (areRestPagesMutating) {
      shouldUpdateAfterMutation.current = true;
    }
    if (isCurrentPageMutating) {
      queryClient.removeQueries(queryKeys);
      if (!areRestPagesMutating) {
        shouldUpdateAfterMutation.current = false;
      }
    }
    const newQueryKeys = updateQueryKeysIterator(queryKeys, change);
    if (!newQueryKeys) {
      return;
    }
    updateEnableQueryEntitiesWithKeysChange(newQueryKeys);
  });

  useEffect(() => {
    updateEnableQueryEntitiesWithKeysChange(queryKeys);
  }, [queryKeys, updateEnableQueryEntitiesWithKeysChange]);

  useEffect(() => {
    if (isCurrentPageMutating > 0) {
      shouldUpdateAfterMutation.current = true;
    }
  }, [isCurrentPageMutating]);

  useEffect(() => {
    if (isCurrentPageMutating || areRestPagesMutating || !shouldUpdateAfterMutation.current || isFetching) {
      return;
    }
    setEnableQueryEntities?.(true);
    if (queryKeys !== undefined) {
      const cachedData = queryClient.getQueryData(queryKeys);
      if (cachedData === undefined) {
        refetch?.();
      }
    }
    shouldUpdateAfterMutation.current = false;
  }, [
    isCurrentPageMutating,
    areRestPagesMutating,
    isFetching,
    queryKeys,
    queryClient,
    setEnableQueryEntities,
    refetch,
  ]);

  useEffect(() => {
    if (shouldRefetchAfterProcessing.current && !isFetching && queryKeys !== undefined) {
      queryClient.refetchQueries(queryKeys);
      shouldRefetchAfterProcessing.current = false;
    }
  }, [isFetching, queryClient, queryKeys]);

  useEffect(() => {
    setRows(getRowData(data));
    setMasterSelected(false);
    setSelectedRowsCount(0);

    if (onSelectionChange) {
      onSelectionChange([]);
    }
  }, [onSelectionChange, data]);

  useEffect(() => {
    if (cacheKey) {
      storage.set(columnsCachePrefix + cacheKey, JSON.stringify(hiddenColumns));
    }
  }, [cacheKey, hiddenColumns]);

  const handleQueryChange = useCallback(
    (queryChange: Partial<ITableQuery<string>>, change: QueryChangeType) => {
      if (!onQueryChange) {
        return;
      }

      updateEnableQueryEntitiesWithQueryChange(change);

      const nQuery = {
        ...query,
        ...queryChange,
      };

      if (isPageResetNeeded(change)) {
        nQuery.page = 0;
      }

      onQueryChange({ query: nQuery, change });
      if (window.location.href.includes('?')) {
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    },
    [onQueryChange, updateEnableQueryEntitiesWithQueryChange, query]
  );

  useEffect(() => {
    const queryFromSearch = getQueryFromURLSearchParams<S>();
    if (queryFromSearch) {
      handleQueryChange({ ...queryFromSearch }, QueryChangeType.Other);
    }
  }, [handleQueryChange]);

  const deleteFilterField = useCallback(
    (fieldName: string) => {
      const newFilterBy = { ...query.filterBy };
      delete newFilterBy[fieldName];
      handleQueryChange({ ...query, filterBy: newFilterBy }, QueryChangeType.Filter);
    },
    [handleQueryChange, query]
  );

  const updateFilterBySpace = useCallback(
    (fieldName: string, prevPromotionFilter?: PromotionFilter) => {
      const newPromotionFilter = new PromotionFilter(prevPromotionFilter?.serialize());
      newPromotionFilter.space = activeSpace?.id;
      const newFilterBy = { ...query.filterBy, [fieldName]: newPromotionFilter };
      handleQueryChange({ ...query, filterBy: newFilterBy }, QueryChangeType.Filter);
    },
    [activeSpace?.id, handleQueryChange, query]
  );

  useEffect(() => {
    const prevPromotionsFilter = promotionsFilter?.filter;
    const isPrevPromotionFilterEmpty =
      prevPromotionsFilter !== undefined &&
      !prevPromotionsFilter.env.length &&
      !prevPromotionsFilter.space &&
      !!promotionsFilter?.fieldName;

    if (isPrevPromotionFilterEmpty && !activeSpace?.id) {
      deleteFilterField(promotionsFilter?.fieldName);
    }
  }, [activeSpace?.id, deleteFilterField, promotionsFilter]);

  useEffect(
    function updateFilterBySpaceLessModuleChanges() {
      if (!promotionsFilter) return;

      if (isSpaceLessModule) {
        const { fieldName, filter: prevPromotionFilter } = promotionsFilter;
        updateFilterBySpace(fieldName, prevPromotionFilter);
      }
    },
    // should listen only to activeSpace changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSpaceLessModule]
  );

  useEffect(() => {
    if (!promotionsFilter || promotionsFilter.filter?.space?.serialize() === activeSpace?.id?.serialize()) {
      return;
    }
    const { fieldName, filter: prevPromotionFilter } = promotionsFilter;
    updateFilterBySpace(fieldName, prevPromotionFilter);
    // should listen only to activeSpace changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSpace?.id]);

  const onQueryRefresh =
    refetch !== undefined
      ? () => {
          refetch();
          snackbarService.success('Table was refreshed');
        }
      : undefined;

  const onQueryReset = () => {
    handleQueryChange({ ...QueryHelpers.getInitValue() }, QueryChangeType.Other);
    snackbarService.success('Table query was reset');
  };

  const onQueryShare = () => {
    const { href } = window.location;
    const paramsIdx = href.indexOf('?');
    const hrefWithoutParams = paramsIdx === -1 ? href : href.slice(0, paramsIdx);
    const hrefWithoutLeadingSlash = hrefWithoutParams.endsWith('/')
      ? hrefWithoutParams.substring(0, hrefWithoutParams.length - 1)
      : hrefWithoutParams;
    const queryAsString = urltron.stringify({
      query: {
        ...query,
        filterBy: QueryHelpers.serializeFilterBy(query.filterBy),
      },
    });
    clipboardWrite(`${hrefWithoutLeadingSlash}?${queryAsString}`);
    snackbarService.success('Table parameters saved to clipboard');
  };

  const onClearAllFilters = () => {
    handleQueryChange({ ...query, filterBy: {} }, QueryChangeType.Other);
  };

  const onSortChange = (event: MouseEvent<HTMLElement>, field?: string) => {
    const { sortMap, sortBy } = query;
    const { withMultipleSort } = options;

    if (!field) {
      return;
    }

    let nSortBy = [...sortBy];
    const s = sortMap.get(field.toString());

    if (!s) {
      const nextSort = { ord: SortDirection.Descending, field };
      if (event.shiftKey && sortMap.size && withMultipleSort) {
        nSortBy.push(nextSort);
      } else {
        nSortBy = [nextSort];
      }
    } else {
      s.ord = s.ord === SortDirection.Descending ? SortDirection.Ascending : SortDirection.Descending;
      nSortBy = sortBy.reduce((acc: ITableColumnSort<string>[], next: ITableColumnSort<string>) => {
        acc.push(next.field === field ? s : next);
        return acc;
      }, []);
    }

    if (onQueryChange) {
      handleQueryChange({ sortBy: nSortBy }, QueryChangeType.Sort);
    }
  };

  const onSortClear = (field?: string) => {
    if (!field) {
      return;
    }

    const sortBy = query.sortBy.filter((s: ITableColumnSort<string>) => s.field !== field);
    if (onQueryChange) {
      handleQueryChange({ sortBy }, QueryChangeType.SortClear);
    }
  };

  const getPageChangeDirection = useEventCallback((nextPage: number) => {
    if (nextPage === 0) {
      return QueryChangeType.FirstPage;
    }
    return nextPage < query.page ? QueryChangeType.PrevPage : QueryChangeType.NextPage;
  });

  const onPageChange = useEventCallback((nextPage: number) => {
    const direction = getPageChangeDirection(nextPage);
    handleQueryChange({ page: nextPage }, direction);
  });

  useEffect(() => {
    if (data.length === 0 && query.page > 0 && !debounceProcessing) {
      onPageChange(query.page - 1);
    }
  }, [query.page, query.rowsPerPage, data.length, debounceProcessing, onPageChange]);

  const onRowsPerPageChange = (rowsPerPage: number) => {
    if (onQueryChange) {
      handleQueryChange(
        {
          rowsPerPage,
          page: 0,
        },
        QueryChangeType.EntitiesPerPage
      );
    }
  };

  const onFilterChange = (key: string, value: Filter | undefined) => {
    const { withMultipleFilter } = options;
    if (!key) {
      return;
    }

    if (!value) {
      onFilterClear(key);
      return;
    }

    const filterBy = withMultipleFilter
      ? {
          ...query.filterBy,
          [key.toString()]: value,
        }
      : { [key.toString()]: value };

    if (onQueryChange) {
      handleQueryChange({ filterBy }, QueryChangeType.Filter);
    }
  };

  const onFilterClear = (key: string) => {
    if (!key) {
      return;
    }

    const filterBy = { ...query.filterBy };
    delete filterBy[key.toString()];

    if (onQueryChange) {
      handleQueryChange({ filterBy }, QueryChangeType.FilterClear);
    }
  };

  const onMasterSelection = (selected: boolean) => {
    const selectedItems: D[] = [];

    const nRows = rows.map((row: IRowData<D>) => {
      if (disableSelection?.(row.item)) {
        return row;
      }
      if (selected) {
        selectedItems.push(row.item);
      }
      row.selected = selected;
      return row;
    });

    setRows(nRows);
    setSelectedRowsCount(selectedItems.length);
    setMasterSelected(selected);

    if (onSelectionChange) {
      onSelectionChange(selectedItems);
    }
  };

  const onSelection = (selectedRow: IRowData<D>, selected: boolean) => {
    const selectedItems: D[] = [];

    const nRows = rows.map((row: IRowData<D>) => {
      if (selectedRow.id === row.id) {
        row.selected = selected;
      }

      if (row.selected) {
        selectedItems.push(row.item);
      }

      return row;
    });

    setRows(nRows);
    setSelectedRowsCount(selectedItems.length);
    setMasterSelected(selectedItems.length === nRows.length);

    if (onSelectionChange) {
      onSelectionChange(selectedItems);
    }
  };

  const onDetailsChange = (rowData: IRowData<D>, val: boolean) => {
    const nRows = rows.map((rd: IRowData<D>) => {
      if (rd.id === rowData.id) {
        rd.detailsOpen = val;
        return rd;
      }

      if (!options.withMultipleDetails) {
        rd.detailsOpen = false;
      }

      return rd;
    });

    setRows(nRows);
  };

  const onRowProcessing = (selectedRow: IRowData<D>, isProcessing: boolean) => {
    const nRows = rows.map((row: IRowData<D>) => {
      if (selectedRow.id === row.id) {
        row.processing = isProcessing;
      }

      return row;
    });

    setRows(nRows);
  };

  const getHiddenClasses = useCallback(
    (isHidden: boolean | undefined) => {
      if (isHidden) {
        return {
          headerCell: classes.displayNone,
          cell: classes.displayNone,
        };
      }
    },
    [classes]
  );

  return {
    ...query,
    disableQueryShare,
    options,
    renderColumnsCount,
    rows,
    placeholder,
    components,
    data,
    columns,
    error,
    size,
    rowsPerPageOptions,
    total,
    hiddenColumns,
    toolbarActions,
    toolbarActionsLength,
    actions,
    details,
    actionsLength,
    selectedRowsCount,
    masterSelected,
    isMasterSelectionDisabled,
    queryTags,
    getRowKey,
    customQueryRowComponent,

    processing: debounceProcessing,

    classes,

    onSortChange,
    onSortClear,

    onPageChange,
    onRowsPerPageChange,

    onFilterChange,
    onFilterClear,

    setHiddenColumns,
    onMasterSelection,
    onSelection,
    disableSelection,

    onDetailsChange,

    onRowProcessing,

    onQueryRefresh,
    onQueryReset,
    onQueryShare,
    onClearAllFilters,
    onRowClick,

    getHiddenClasses,

    getEntityURL,
  };
}
